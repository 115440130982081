import React from "react";

import './ui.css'

function Button({className, children}) {
  return (
    <button className={`button ${className ? className : ""}`}>
      {children}
    </button>
  );
}

export default Button;
