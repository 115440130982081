import React from "react";
import { Link } from "react-router-dom";

function ListSecond() {
  return (
    <ul className="header__list header__list--second">
      <li className="header__item">
        <Link className="header__link" to="#">
          Блог
        </Link>
      </li>
      <li className="header__item">
        <Link className="header__link" to="#">
          Планы подписки
        </Link>
      </li>
    </ul>
  );
}

export default ListSecond;
