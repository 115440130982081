import React, { useState } from "react";
import Sprite from "../../UI/sprite/Sprite";
import List from "./List";
import ListSecond from "./ListSecond";
import { Link } from "react-router-dom";

import "./header.css";

function Header() {
  const [active, setActive] = useState(false);
  return (
    <header className={`header ${active ? "header--active" : ""}`}>
      <div className="container-b header__inner">
        <button className="header__menu-button" onClick={() => setActive(b => !b)}>
          <Sprite icon="hamburger" width={24} height={24} />
        </button>
        <a className="header__logo" href="/">
          <Sprite icon="logo" width={91} height={23} />
        </a>
        <nav className="header__nav">
          <div className="header__menu">
            <List />
            <ListSecond />
            <button className="header__button-icon">
              <span>Для компаний</span>
              <Sprite icon="lightning" width={16} height={16} />
            </button>
          </div>
          <div className="header__wrapper">
            <Link className="header__sign" to="#">
              <span>Войти</span>
              <Sprite icon='avatar' width={20} height={20} />
            </Link>
            <Link className="header__basket">
              <Sprite icon="basket" width={16} height={16} />
              <div className="header__quantity">
                <span>8</span>
              </div>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
