import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Homepage from "./pages/Homepage";

import "./styles/main.css";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <main className="main">
        <Routes>
          <Route path="/">
            <Route index element={<Homepage />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
