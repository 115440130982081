import React from "react";
import Form from "./Form";
import Nav from "./Nav";

import "./top.css";
import List from "./List";

function Top() {
  return (
    <section className="top">
      <video className="top__video" poster="./images/top-poster.png" />
      <div className="container-b top__inner">
        <p className="top__above-title">Российская стоковая площадка</p>
        <h1 className="top__title">
          500+ млн материалов для реализации ваших идей{" "}
        </h1>
        <p className="top__text">
          Различные виды контента на одной платформе от авторов со всего мира с
          оплатой в рублях
        </p>
        <Form />
        <Nav />
        <List />
      </div>
    </section>
  );
}

export default Top;
