import React from "react";
import Top from "../components/top/Top";
import About from "../components/about/About";
import Line from "../components/line/Line";
import Company from "../components/company/Company";

function Homepage() {
  
  return (
    <>
      <Line />
      <Top />
      <About />
      <Company />
    </>
  );
}

export default Homepage;
