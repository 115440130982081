import React from "react";
import { Link } from "react-router-dom";
import Sprite from "../../UI/sprite/Sprite";

function Nav() {
  return (
    <nav className="top__nav">
      <ul className="top__nav-list">
        <li className="top__nav-item">
          <Link className="top__link" to="#">
            <Sprite icon="video" width={24} height={24} />
            <span>Видео</span>
          </Link>
        </li>
        <li className="top__nav-item">
          <Link className="top__link" to="#">
            <Sprite icon="camera" width={24} height={24} />
            <span>Фотографии</span>
          </Link>
        </li>
        <li className="top__nav-item">
          <Link className="top__link" to="#">
            <Sprite icon="music" width={24} height={24} />
            <span>Музыка</span>
          </Link>
        </li>
        <li className="top__nav-item">
          <Link className="top__link" to="#">
            <Sprite icon="image" width={24} height={24} />
            <span>Иллюстрации</span>
          </Link>
        </li>
        <li className="top__nav-item">
          <Link className="top__link" to="#">
            <Sprite icon="sound" width={24} height={24} />
            <span>Звуки</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
