import React, { useEffect, useRef, useState } from "react";

function Documents() {
  const ref = useRef();
  const [show, setShow] = useState(false);
  useEffect(() => {
    function checkScroll() {
      let scroll =
      ref.current.getBoundingClientRect().top
      let position = (ref.current.offsetHeight + (ref.current.offsetHeight / 2)) ;
      if (
        !show &&
        position> scroll
      ) {
        setShow(true);
      } else if (
        show &&
       position < scroll
      ) {
        setShow(false);
      }
    }

    window.removeEventListener("scroll", checkScroll);
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [show]);
  return (
    <div
      className={`company__documents ${
        show ? "company__documents--active" : ""
      }`}
      ref={ref}
    >
      <div className="company__document company__document-first">
        <img className="company__document-image" src="./images/doc3.png" />
      </div>
      <div className="company__document company__document-second">
        <img className="company__document-image" src="./images/doc2.png" />
      </div>
      <div className="company__document company__document-last">
        <img className="company__document-image" src="./images/doc.png" />
      </div>
    </div>
  );
}

export default Documents;
