import React from "react";
import Sprite from "../../UI/sprite/Sprite";

function Payment() {
  return (
    <div className="company__payment">
      <div className="company__image-wrapper">
        <img className="company__image" src="./images/image.png" alt="image" />
        <button className='company__image-button'>
          <Sprite icon="image-icon" width={21} height={21}  />
        </button>
      </div>
      <div className="company__payment-content">
        <img className="company__text" src="./images/text.svg" alt="text" />
        <div className="company__price">
            <span>1 500 ₽</span>
        </div>
      </div>
    </div>
  );
}

export default Payment;
