import React, { useEffect, useRef, useState } from "react";
import Sprite from "../../UI/sprite/Sprite";

function SelectList({ showList, onClose, setSelected }) {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClose(e.target);
      }
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [showList]);
  return (
    <ul
      className={`top__select-list ${
        showList ? "top__select-list--active" : ""
      }`}
      ref={ref}
    >
      <li className="top__select-item">
        <button
          className="top__item-button"
          type="button"
          onClick={() => {
            setSelected("Видео");
            onClose(null);
          }}
        >
          Видео
        </button>
      </li>
      <li className="top__select-item">
        <button
          className="top__item-button"
          type="button"
          onClick={() => {
            setSelected("Фотографии");
            onClose(null);
          }}
        >
          Фотографии
        </button>
      </li>
      <li className="top__select-item">
        <button
          className="top__item-button"
          type="button"
          onClick={() => {
            setSelected("Музыка");
            onClose(null);
          }}
        >
          Музыка
        </button>
      </li>
      <li className="top__select-item">
        <button
          className="top__item-button"
          type="button"
          onClick={() => {
            setSelected("Иллюстрации");
            onClose(null);
          }}
        >
          Иллюстрации
        </button>
      </li>
      <li className="top__select-item">
        <button
          className="top__item-button"
          type="button"
          onClick={() => {
            setSelected("Звуки");
            onClose(null);
          }}
        >
          Звуки
        </button>
      </li>
    </ul>
  );
}

function Select() {
  const [selected, setSelected] = useState("Музыка");
  const [showList, setShowList] = useState(false);
  const buttonRef = useRef();


  return (
    <div className="top__select">
      <button
        className="top__select-button"
        type="button"
        ref={buttonRef}
        onClick={() => setShowList((b) => !b)}
      >
        <span>{selected}</span>
        <Sprite icon="arrow-bottom" width={16} height={16} />
      </button>
      <SelectList
        showList={showList}
        setSelected={setSelected}
        onClose={(button) => {
          if (buttonRef.current == button) return;
          setShowList(false);
        }}
      />
    </div>
  );
}

export default Select;
