import React from "react";

import "./about.css";
import Slider from "./Slider";

function About() {
  return (
    <section className="about">
      <div className="container about__inner">
        <div className="about__wrapper">
          <Slider />
        </div>
        <div className="about__descr">
          <h3 className="about__title">О нас</h3>
          <p className="about__text">
            Наша команда имеет огромный опыт сотрудничества с мировыми стоковыми
            платформами. Благодаря накопленным знаниям появился Hi-Res —
            маркетплейс для покупки и продажи качественного мультимедийного
            контента.{" "}
            <span>
              Мы тщательно продумали и разработали нашу площадку с учетом всех
              потребностей российских клиентов.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
