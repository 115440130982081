import React from "react";
import { Link } from "react-router-dom";
import Sprite from "../../UI/sprite/Sprite";

function List() {
  return (
    <ul className="header__list">
      <li className="header__item">
        <Sprite icon="video" width={20} height={20} />
        <Link className="header__link" to="#">
          Видео
        </Link>
      </li>
      <li className="header__item">
        <Sprite icon="camera" width={20} height={20} />

        <Link className="header__link" to="#">
          Фотографии
        </Link>
      </li>
      <li className="header__item">
      <Sprite icon="music" width={20} height={20} />
        <Link className="header__link" to="#">
          Музыка
        </Link>
      </li>
      <li className="header__item">
      <Sprite icon="image" width={20} height={20} />
        <Link className="header__link" to="#">
          Иллюстрации
        </Link>
      </li>
      <li className="header__item">
      <Sprite icon="sound" width={20} height={20} />
        <Link className="header__link" to="#">
          Звуки
        </Link>
      </li>
    </ul>
  );
}

export default List;
