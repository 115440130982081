import React, { useState } from "react";
import Sprite from "../../UI/sprite/Sprite";
import Select from "./Select";

function Form() {
  const [value, setValue] = useState("");
  return (
    <form className="top__form">
      <Select />
      <input
        className="top__input"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Введите ваш запрос |"
      />
      <button className="top__button">
        <Sprite width={18} height={18} icon="search" />
      </button>
    </form>
  );
}

export default Form;
